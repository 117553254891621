import qs from 'qs';
import { createRouter, createWebHistory } from 'vue-router';
import middleware from './middleware';
import authMiddleware from './middleware/auth';
import guestMiddleware from './middleware/guest';
import permissionMiddleware from './middleware/permission';
import { useInfoStore } from '../stores/info';

import AuthLayout from '../layouts/AuthLayout.vue';
import DefaultLayout from '../layouts/DefaultLayout.vue';

const routes = [
  {
    path: '/',
    component: () => import('../views/HomeView.vue'),
    meta: { layout: DefaultLayout, middleware: [authMiddleware] },
  },

  {
    path: '/apis',
    children: [
      {
        path: '',
        component: () => import('../views/apis/IndexView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('apis.read')],
        },
      },
      {
        path: 'add',
        component: () => import('../views/apis/AddView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('apis.create')],
        },
      },
      {
        path: ':id',
        component: () => import('../views/apis/ViewView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('apis.read')],
        },
      },
    ],
  },

  {
    path: '/articles',
    children: [
      {
        path: 'comments',
        children: [
          {
            path: '',
            component: () => import('../views/comments/IndexView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('comments.read'),
              ],
            },
          },
        ],
      },
      {
        path: '',
        component: () => import('../views/articles/IndexView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('articles.read')],
        },
      },
      {
        path: 'add',
        component: () => import('../views/articles/AddView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('articles.create')],
        },
      },
      {
        path: ':id',
        component: () => import('../views/articles/ViewView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('articles.read')],
        },
      },
      {
        path: ':id/edit',
        component: () => import('../views/articles/EditView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('articles.update')],
        },
      },
    ],
  },

  {
    path: '/banners/',
    redirect: '/banners/unbranded',
    children: [
      {
        path: 'unbranded',
        component: () => import('../views/banners/UnbrandedView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('banners.update')],
        },
      },
      {
        path: 'gg-shiver',
        component: () => import('../views/banners/GGShiverView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('banners.update')],
        },
      },
    ],
  },

  {
    path: '/coin-settings',
    children: [
      {
        path: '',
        component: () => import('../views/coin-settings/IndexView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [
            authMiddleware,
            permissionMiddleware('coinsettings.read'),
          ],
        },
      },
      {
        path: ':id/edit',
        component: () => import('../views/coin-settings/EditView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [
            authMiddleware,
            permissionMiddleware('coinsettings.update'),
          ],
        },
      },
    ],
  },

  {
    path: '/cities/',
    children: [
      {
        path: 'places',
        children: [
          {
            path: '',
            component: () => import('../views/places/IndexView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [authMiddleware, permissionMiddleware('places.read')],
            },
          },
          {
            path: 'add',
            component: () => import('../views/places/AddView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('places.create'),
              ],
            },
          },
          {
            path: ':id',
            component: () => import('../views/places/ViewView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [authMiddleware, permissionMiddleware('places.read')],
            },
          },
          {
            path: ':id/edit',
            component: () => import('../views/places/EditView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('places.update'),
              ],
            },
          },
        ],
      },
      {
        path: '',
        component: () => import('../views/cities/IndexView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('cities.read')],
        },
      },
      {
        path: 'add',
        component: () => import('../views/cities/AddView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('cities.create')],
        },
      },
      {
        path: ':id',
        component: () => import('../views/cities/ViewView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('cities.read')],
        },
      },
      {
        path: ':id/edit',
        component: () => import('../views/cities/EditView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('cities.update')],
        },
      },
    ],
  },

  {
    path: '/events',
    children: [
      {
        path: '',
        component: () => import('../views/events/IndexView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('events.read')],
        },
      },
      {
        path: 'add',
        component: () => import('../views/events/AddView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('events.create')],
        },
      },
      {
        path: ':id',
        component: () => import('../views/events/ViewView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('events.read')],
        },
      },
      {
        path: ':id/edit',
        component: () => import('../views/events/EditView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('events.update')],
        },
      },
    ],
  },

  {
    path: '/galleries',
    children: [
      {
        path: '',
        component: () => import('../views/galleries/IndexView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('galleries.read')],
        },
      },
      {
        path: 'add',
        component: () => import('../views/galleries/AddView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [
            authMiddleware,
            permissionMiddleware('galleries.create'),
          ],
        },
      },
      {
        path: ':id',
        component: () => import('../views/galleries/ViewView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('galleries.read')],
        },
      },
      {
        path: ':id/edit',
        component: () => import('../views/galleries/EditView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [
            authMiddleware,
            permissionMiddleware('galleries.update'),
          ],
        },
      },
    ],
  },

  {
    path: '/login',
    component: () => import('../views/LoginView.vue'),
    meta: { layout: AuthLayout, middleware: [guestMiddleware] },
  },

  {
    path: '/orders',
    children: [
      {
        path: '',
        component: () => import('../views/orders/IndexView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('orders.read')],
        },
      },
      {
        path: ':id',
        component: () => import('../views/orders/ViewView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('orders.read')],
        },
      },
      {
        path: ':id/edit',
        component: () => import('../views/orders/EditView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('orders.update')],
        },
      },
    ],
  },

  {
    path: '/pages/',
    redirect: '/pages/gg-shiver',
    children: [
      {
        path: 'gg-shiver',
        component: () => import('../views/pages/GGShiverView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('pages.update')],
        },
      },
      {
        path: 'faq',
        component: () => import('../views/pages/FAQView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('pages.update')],
        },
      },
      {
        path: 'privacy-policy',
        component: () => import('../views/pages/PrivacyPolicyView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('pages.update')],
        },
      },
      {
        path: 'tos',
        component: () => import('../views/pages/ToSView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('pages.update')],
        },
      },
    ],
  },

  {
    path: '/products',
    children: [
      {
        path: 'attributes',
        children: [
          {
            path: '',
            component: () => import('../views/attributes/IndexView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('attributes.read'),
              ],
            },
          },
          {
            path: 'add',
            component: () => import('../views/attributes/AddView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('attributes.create'),
              ],
            },
          },
          {
            path: ':id',
            component: () => import('../views/attributes/ViewView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('attributes.read'),
              ],
            },
          },
          {
            path: ':id/edit',
            component: () => import('../views/attributes/EditView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('attributes.update'),
              ],
            },
          },
        ],
      },
      {
        path: 'attribute-values',
        children: [
          {
            path: '',
            component: () => import('../views/attribute-values/IndexView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('attributes.read'),
              ],
            },
          },
          {
            path: 'add',
            component: () => import('../views/attribute-values/AddView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('attributes.create'),
              ],
            },
          },
          {
            path: ':id',
            component: () => import('../views/attribute-values/ViewView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('attributes.read'),
              ],
            },
          },
          {
            path: ':id/edit',
            component: () => import('../views/attribute-values/EditView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('attributes.update'),
              ],
            },
          },
        ],
      },
      {
        path: '',
        component: () => import('../views/products/IndexView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('products.read')],
        },
      },
      {
        path: 'add',
        component: () => import('../views/products/AddView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('products.create')],
        },
      },
      {
        path: ':id',
        component: () => import('../views/products/ViewView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('products.read')],
        },
      },
      {
        path: ':id/edit',
        component: () => import('../views/products/EditView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('products.update')],
        },
      },
    ],
  },

  {
    path: '/profile',
    component: () => import('../views/ProfileView.vue'),
    meta: { layout: DefaultLayout, middleware: [authMiddleware] },
  },

  // {
  //   path: '/receipts',
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('../views/receipts/IndexView.vue'),
  //       meta: {
  //         layout: DefaultLayout,
  //         middleware: [authMiddleware, permissionMiddleware('receipts.read')],
  //       },
  //     },
  //   ],
  // },

  {
    path: '/roles',
    children: [
      {
        path: '',
        component: () => import('../views/roles/IndexView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('roles.read')],
        },
      },
      {
        path: 'add',
        component: () => import('../views/roles/AddView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('roles.create')],
        },
      },
      {
        path: ':id',
        component: () => import('../views/roles/ViewView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('roles.read')],
        },
      },
      {
        path: ':id/edit',
        component: () => import('../views/roles/EditView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('roles.update')],
        },
      },
    ],
  },

  {
    path: '/stores',
    children: [
      {
        path: 'campaigns',
        children: [
          {
            path: '',
            component: () => import('../views/campaigns/IndexView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('campaigns.read'),
              ],
            },
          },
          {
            path: 'add',
            component: () => import('../views/campaigns/AddView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('campaigns.create'),
              ],
            },
          },
          {
            path: ':id',
            component: () => import('../views/campaigns/ViewView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('campaigns.read'),
              ],
            },
          },
          {
            path: ':id/edit',
            component: () => import('../views/campaigns/EditView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [
                authMiddleware,
                permissionMiddleware('campaigns.update'),
              ],
            },
          },
        ],
      },
      {
        path: 'codes',
        children: [
          {
            path: '',
            component: () => import('../views/codes/IndexView.vue'),
            meta: {
              layout: DefaultLayout,
              middleware: [authMiddleware, permissionMiddleware('codes.read')],
            },
          },
        ],
      },
      {
        path: '',
        component: () => import('../views/stores/IndexView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('stores.read')],
        },
      },
      {
        path: 'add',
        component: () => import('../views/stores/AddView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('stores.create')],
        },
      },
      {
        path: ':id',
        component: () => import('../views/stores/ViewView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('stores.read')],
        },
      },
      {
        path: ':id/edit',
        component: () => import('../views/stores/EditView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('stores.update')],
        },
      },
    ],
  },

  {
    path: '/users',
    children: [
      {
        path: '',
        component: () => import('../views/users/IndexView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('users.read')],
        },
      },
      {
        path: 'add',
        component: () => import('../views/users/AddView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('users.create')],
        },
      },
      {
        path: ':id',
        component: () => import('../views/users/ViewView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('users.read')],
        },
      },
      {
        path: ':id/edit',
        component: () => import('../views/users/EditView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('users.update')],
        },
      },
      {
        path: ':id/reset-password',
        component: () => import('../views/users/ResetPasswordView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('users.update')],
        },
      },
    ],
  },

  {
    path: '/members',
    children: [
      {
        path: '',
        component: () => import('../views/members/IndexView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('members.read')],
        },
      },
      {
        path: ':id',
        component: () => import('../views/members/ViewView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('members.read')],
        },
      },
      {
        path: ':id/add_coin',
        component: () => import('../views/members/FormView.vue'),
        meta: {
          layout: DefaultLayout,
          middleware: [authMiddleware, permissionMiddleware('members.create')],
        },
      },
    ],
  },

  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/NotFoundView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const str = qs.stringify(query, { arrayFormat: 'indices', encode: false });
    return str;
  },
});

router.beforeEach((to, from, next) => {
  const infoStore = useInfoStore();
  // Reset error
  infoStore.$patch({ error: null });
  middleware(to, from, next);
});

export default router;
