import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';
import auth from './plugins/Auth';
import alert from './plugins/Alert';
import confirmation from './plugins/Confirmation';
import { layoutStorePlugin } from './stores/layout';

import './assets/scss/main.scss';

const app = createApp(App);
const pinia = createPinia();

pinia.use(layoutStorePlugin);

app.use(pinia);
app.use(router);
app.use(alert);
app.use(auth);
app.use(confirmation);

app.mount('#app');
