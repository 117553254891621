import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useConfirmationStore = defineStore('confirmation', () => {
  const data = ref(null);

  function setData(value) {
    data.value = value;
  }

  return { data, setData };
});
