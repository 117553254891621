<template>
  <div class="wrapper d-flex justify-content-center">
    <main
      class="flex-grow-1 flex-md-grow-0 d-flex min-vh-100 justify-content-center align-items-center p-4 p-md-5"
    >
      <div class="inner p-5">
        <div class="text-center mb-5">
          <img
            alt="SHVR"
            class="logo mw-100 mb-3"
            src="../assets/img/auth/logo.png"
            srcset="
              ../assets/img/auth/logo.png,
              ../assets/img/auth/logo-2x.png 2x
            "
            height="32"
          />
          <h1 class="fs-5 fw-bold text-white">Gudang Garam Shiver</h1>
        </div>

        <slot />
      </div>
    </main>

    <AlertModal />
  </div>
</template>

<script>
import AlertModal from '../components/alert/AlertModal.vue';

export default {
  components: { AlertModal },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: url(@/assets/img/auth/bg.jpg) right/cover;

  @media (min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    background-image: url(@/assets/img/auth/bg-2x.jpg);
  }

  @include media-breakpoint-up(xxl) {
    background-image: url(@/assets/img/auth/bg-2x.jpg);
  }
}

.inner {
  background: #132a3e;
  width: 100%;
  border: 1px solid #51e2ff;

  @include media-breakpoint-up(sm) {
    width: rem(360);
  }
}
</style>
