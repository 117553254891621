import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useInfoStore = defineStore('info', () => {
  const error = ref(null);

  function setError(value) {
    error.value = value;
  }

  return { error, setError };
});
