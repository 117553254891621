import get from 'lodash/get';
import { watch } from 'vue';
import api from '../api';
import { useAuthStore } from '../stores/auth';
import User from '../utils/models/User';

export class Auth {
  static #instance;

  static get instance() {
    if (Auth.#instance) return Auth.#instance;
    return (Auth.#instance = new Auth());
  }

  #store;

  constructor() {
    this.#store = useAuthStore();
    this._initAPI();
    this._load();
  }

  get data() {
    return this.#store.data;
  }

  get user() {
    return this.#store.user && new User(this.#store.user);
  }

  get isLoggedIn() {
    return Boolean(this.user);
  }

  get isLoading() {
    return this.#store.isLoading;
  }

  /**
   * Log in.
   *
   * @param {Object} credentials
   */
  async login(credentials) {
    const response = await api.post('core/auth/login', credentials, {
      withCredentials: true,
    });
    const data = response.data.data;

    this._storeData(data);
    await this._fetchUser();
  }

  /**
   * Log out.
   */
  logout() {
    localStorage.removeItem('auth');
    location.href = import.meta.env.BASE_URL;
  }

  watch(expression, callback, options) {
    const unwatch = watch(
      () => this[expression],
      function () {
        callback(...arguments);
        options && options.once && unwatch && unwatch();
      },
      options
    );
  }

  /**
   * Load using saved data.
   */
  async _load() {
    this.#store.setIsLoading(true);

    try {
      const saved = JSON.parse(localStorage.getItem('auth') || null);

      if (saved) {
        this._storeData(saved);
        await this._fetchUser();
      }
    } finally {
      this.#store.setIsLoading(false);
    }
  }

  /**
   * Initialize Axios API integration.
   */
  _initAPI() {
    api.interceptors.response.use(
      // Any status code that lie within the range of 2xx cause this function to
      // trigger
      (response) => response,
      // Any status codes that falls outside the range of 2xx cause this
      // function to trigger
      async (error) => {
        const code = get(error, 'response.data.error.code');

        if (code === 'TokenExpiredError') {
          try {
            await this._refreshToken();

            error.config.headers.Authorization =
              api.defaults.headers.common['Authorization'];

            return api.request(error.config);
          } catch (err) {
            console.warn(err);
            this.logout();

            return Promise.reject(error);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  /**
   * Fethc user data.
   */
  async _fetchUser() {
    const response = await api.get(`core/auth/me`);
    this.#store.setUser(response.data.data);
  }

  /**
   * Set API bearer token.
   */
  _setAPIToken() {
    const token = `Bearer ${this.data.accessToken}`;
    api.defaults.headers.common['Authorization'] = token;
  }

  /**
   * Remove API bearer token.
   */
  _removeAPIToken() {
    delete api.defaults.headers.common['Authorization'];
  }

  async _refreshToken() {
    const response = await api.post('core/auth/refresh-token', null, {
      withCredentials: true,
    });
    const data = response.data.data;

    this._storeData(data);
  }

  _storeData(data) {
    localStorage.setItem('auth', JSON.stringify(data));
    this.#store.setData(data);
    this._setAPIToken();
  }
}

export default {
  install: (app) => {
    app.config.globalProperties.$auth = Auth.instance;
  },
};
