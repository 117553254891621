import get from 'lodash/get';
import { useAlertStore } from '../stores/alert';

export class Alert {
  static #instance;

  static get instance() {
    if (Alert.#instance) return Alert.#instance;
    return (Alert.#instance = new Alert());
  }

  #store;

  constructor() {
    this.#store = useAlertStore();
  }

  get data() {
    return this.#store.data;
  }

  /**
   * Alert.
   *
   * @param {String} type Theme.
   * @param {String} message Message or object data.
   * @param {Object} options
   */
  alert(type, message, options = {}) {
    const data = { type, message, options };
    this.#store.setData(data);
  }

  /**
   * Alert for request success.
   *
   * @param {AxiosResponse} response
   * @param {Object} options
   */
  requestSuccess(response, options) {
    const message = get(response, 'data.data.message');
    this.alert('success', message, options);
  }

  /**
   * Alert for request error.
   *
   * @param {Error} error
   * @param {Object} options
   */
  requestError(error, options) {
    let message = get(error, 'response.data.error.message') || error.message;

    this.alert('danger', message, options);
    console.error(error);
  }

  /**
   * Remove alert.
   */
  remove() {
    this.#store.setData(null);
  }
}

export default {
  install: (app) => {
    app.config.globalProperties.$alert = Alert.instance;
  },
};
