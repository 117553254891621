<script>
import authMixin from './mixins/auth';
import { useInfoStore } from './stores/info';
import ErrorView from './views/ErrorView.vue';

export default {
  mixins: [authMixin],
  components: { ErrorView },

  setup() {
    const infoStore = useInfoStore();
    return { infoStore };
  },
};
</script>

<template>
  <ErrorView v-if="infoStore.error" />

  <component v-else-if="$route.meta.layout" :is="$route.meta.layout">
    <RouterView />
  </component>

  <RouterView v-else />
</template>
