import { Auth } from '../../plugins/Auth';
import { useInfoStore } from '../../stores/info';

export default function (permission) {
  return async ({ next }) => {
    const $auth = Auth.instance;
    const infoStore = useInfoStore();

    if (!$auth.isLoggedIn || !$auth.user.can(permission)) {
      infoStore.setError({
        title: 'Akses Denied',
        description: "You don't have permission to access this page.",
      });
    }

    next();
  };
}
