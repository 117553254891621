<template>
  <Sidebar />

  <main class="d-flex flex-column min-vh-100">
    <div class="container flex-grow-1 d-flex flex-column p-4 py-md-5 px-xl-5">
      <slot />
    </div>
  </main>

  <AlertModal />
  <ConfirmationModal />
</template>

<script>
import AlertModal from '../components/alert/AlertModal.vue';
import ConfirmationModal from '../components/alert/ConfirmationModal.vue';
import Sidebar from '../components/layout/Sidebar.vue';

export default {
  components: { AlertModal, ConfirmationModal, Sidebar },
};
</script>

<style lang="scss" scoped>
main {
  margin-left: rem(70);

  @include media-breakpoint-up(xl) {
    margin-left: rem(280);
  }
}
</style>
