import { useConfirmationStore } from '../stores/confirmation';

export class Confirmation {
  static #instance;

  static get instance() {
    if (Confirmation.#instance) return Confirmation.#instance;
    return (Confirmation.#instance = new Confirmation());
  }

  #store;

  constructor() {
    this.#store = useConfirmationStore();
  }

  get data() {
    return this.#store.data;
  }

  /**
   * Confirm.
   *
   * @param {String} message
   * @param {Object} data
   */
  confirm(message, options = {}) {
    const data = { message, options };
    this.#store.setData(data);
  }

  /**
   * Remove confirmation.
   */
  remove() {
    this.#store.setData(null);
  }
}

export default {
  install: (app) => {
    app.config.globalProperties.$confirmation = Confirmation.instance;
  },
};
