<template>
  <div :class="{ 'is-collapsed': isSidebarCollapsed }">
    <div
      v-if="!isSidebarCollapsed"
      class="backdrop d-xl-none position-fixed top-0 bottom-0 start-0 end-0"
      @click="isSidebarCollapsed = true"
    ></div>

    <aside class="d-flex flex-column vh-100 position-fixed l-0 t-0">
      <div class="inner-item position-relative mb-5">
        <div class="logo d-flex align-items-center">
          <img
            alt="SHVR"
            src="../../assets/img/logo.png"
            srcset="../../assets/img/logo.png, ../../assets/img/logo-2x.png 2x"
          />
          <span class="ms-3" v-if="!isSidebarCollapsed"
            >Gudang Garam Shiver</span
          >
        </div>

        <a
          class="toggle d-inline-block d-xl-none lh-1 bg-white rounded-circle shadow position-absolute start-100 top-50 translate-middle"
          href="#"
          @click.prevent="isSidebarCollapsed = !isSidebarCollapsed"
        >
          <ChevronRightIcon v-if="isSidebarCollapsed" />
          <ChevronLeftIcon v-else />
        </a>
      </div>

      <nav class="inner-item flex-grow-1 overflow-auto py-0">
        <div v-for="group of filteredGroups" class="mb-4" :key="group.label">
          <div
            class="small text-muted text-uppercase mb-3"
            :class="{ 'd-none': isSidebarCollapsed }"
          >
            {{ group.label }}
          </div>

          <div v-for="item of group.items" class="menu-item" :key="item.path">
            <router-link
              class="align-items-center text-decoration-none rounded-3"
              :to="item.path"
            >
              <i class="lh-1 fs-5">
                <component :is="{ ...item.icon }" />
              </i>

              <span class="menu-label ms-3">
                {{ item.label }}
              </span>
            </router-link>

            <div v-if="item.items" class="children">
              <div
                v-for="item of item.items"
                class="menu-item"
                :key="item.path"
              >
                <router-link
                  class="text-decoration-none rounded-3"
                  :to="item.path"
                >
                  <span class="menu-label">
                    {{ item.label }}
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div class="inner-item d-flex align-items-center">
        <BootstrapDropdown v-if="isSidebarCollapsed">
          <div class="dropdown">
            <a
              class="d-inline-block"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                width="40"
                height="40"
                class="rounded-circle"
                :alt="$auth.user.name"
                :src="$auth.user.avatarURL"
              />
            </a>

            <div class="dropdown-menu p-3">
              <div class="mb-1">{{ $auth.user.fullname }}</div>
              <a
                class="d-inline-flex text-danger text-decoration-none align-items-center"
                href="#"
                @click.prevent="$auth.logout()"
              >
                <BoxArrowRightIcon class="me-1" />
                Log out
              </a>
            </div>
          </div>
        </BootstrapDropdown>

        <div class="user-text">
          <div>{{ $auth.user.fullname }}</div>
          <a
            class="d-inline-flex text-danger text-decoration-none align-items-center"
            href="#"
            @click.prevent="$auth.logout()"
          >
            <BoxArrowRightIcon class="me-1" />
            Log out
          </a>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import { mapWritableState } from 'pinia';
import { useLayoutStore } from '../../stores/layout';

import BootstrapDropdown from '../bootstrap/BootstrapDropdown.vue';
import BagIcon from 'bootstrap-icons/icons/bag.svg';
import BoxArrowRightIcon from 'bootstrap-icons/icons/box-arrow-right.svg';
import BoxSeamIcon from 'bootstrap-icons/icons/box-seam.svg';
import BuildingIcon from 'bootstrap-icons/icons/building.svg';
import CalendarEventIcon from 'bootstrap-icons/icons/calendar-event.svg';
import ChevronLeftIcon from 'bootstrap-icons/icons/chevron-left.svg';
import ChevronRightIcon from 'bootstrap-icons/icons/chevron-right.svg';
import CoinIcon from 'bootstrap-icons/icons/coin.svg';
import FileTextIcon from 'bootstrap-icons/icons/file-text.svg';
import ImagesIcon from 'bootstrap-icons/icons/images.svg';
import LightningIcon from 'bootstrap-icons/icons/lightning.svg';
import NewspaperIcon from 'bootstrap-icons/icons/newspaper.svg';
import PeopleFillIcon from 'bootstrap-icons/icons/people-fill.svg';
import PersonSquareIcon from 'bootstrap-icons/icons/person-square.svg';
// import ReceiptIcon from 'bootstrap-icons/icons/receipt.svg';
import ShopIcon from 'bootstrap-icons/icons/shop.svg';
import TvIcon from 'bootstrap-icons/icons/tv.svg';
import UiChecksGridIcon from 'bootstrap-icons/icons/ui-checks-grid.svg';
import PeopleIcon from 'bootstrap-icons/icons/people.svg';

export default {
  name: 'TheSidebar',

  components: {
    BootstrapDropdown,
    BoxArrowRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
  },

  data() {
    return {
      groups: [
        {
          label: 'Manage',
          items: [
            {
              path: '/banners',
              icon: TvIcon,
              label: 'Banners',
              access: 'banners.update',
              items: [
                {
                  path: '/banners/unbranded',
                  label: 'Unbranded',
                  access: 'banners.update',
                },
                {
                  path: '/banners/gg-shiver',
                  label: 'GG Shiver',
                  access: 'banners.update',
                },
              ],
            },
            {
              path: '/cities',
              icon: BuildingIcon,
              label: 'Cities',
              access: 'cities.read',
              items: [
                {
                  path: '/cities/places',
                  label: 'Places',
                  access: 'places.read',
                },
              ],
            },
            {
              path: '/galleries',
              icon: ImagesIcon,
              label: 'Galleries',
              access: 'galleries.read',
            },
            {
              path: '/articles',
              icon: NewspaperIcon,
              label: 'Articles',
              access: 'articles.read',
              items: [
                {
                  path: '/articles/comments',
                  label: 'Comments',
                  access: 'comments.read',
                },
              ],
            },
            {
              path: '/events',
              icon: CalendarEventIcon,
              label: 'Events',
              access: 'events.read',
            },
            {
              path: '/pages',
              icon: FileTextIcon,
              label: 'Pages',
              access: 'pages.update',
              items: [
                {
                  path: '/pages/gg-shiver',
                  label: 'GG Shiver',
                  access: 'pages.update',
                },
                {
                  path: '/pages/faq',
                  label: 'FAQ',
                  access: 'pages.update',
                },
                {
                  path: '/pages/privacy-policy',
                  label: 'Privacy Policy',
                  access: 'pages.update',
                },
                {
                  path: '/pages/tos',
                  label: 'Terms of Service',
                  access: 'pages.update',
                },
              ],
            },
          ],
        },

        {
          label: 'Coins',
          items: [
            {
              path: '/coin-settings',
              icon: CoinIcon,
              label: 'Coin Settings',
              access: 'coinsettings.read',
            },
            {
              path: '/products',
              icon: BoxSeamIcon,
              label: 'Products',
              access: 'products.read',
              items: [
                {
                  path: '/products/attributes',
                  label: 'Attributes',
                  access: 'attributes.read',
                },
                {
                  path: '/products/attribute-values',
                  label: 'Attribute Values',
                  access: 'attributes.read',
                },
              ],
            },
            {
              path: '/orders',
              icon: BagIcon,
              label: 'Orders',
              access: 'orders.read',
            },
            {
              path: '/stores',
              icon: ShopIcon,
              label: 'Stores',
              access: 'stores.read',
              items: [
                {
                  path: '/stores/campaigns',
                  label: 'Campaigns',
                  access: 'campaigns.read',
                },
                {
                  path: '/stores/codes',
                  label: 'Codes',
                  access: 'codes.read',
                },
              ],
            },
            {
              path: '/members',
              icon: PeopleIcon,
              label: 'Members',
              access: 'members.read',
            },
            // {
            //   path: '/receipts',
            //   icon: ReceiptIcon,
            //   label: 'Receipts',
            //   access: 'receipts.read',
            // },
          ],
        },

        {
          label: 'Settings',
          items: [
            {
              path: '/profile',
              icon: PersonSquareIcon,
              label: 'Profile',
            },
            {
              path: '/users',
              icon: PeopleFillIcon,
              label: 'Users',
              access: 'users.read',
            },
            {
              path: '/roles',
              icon: UiChecksGridIcon,
              label: 'Roles',
              access: 'roles.read',
            },
            {
              path: '/apis',
              icon: LightningIcon,
              label: 'APIs',
              access: 'apis.read',
            },
          ],
        },
      ],
    };
  },

  computed: {
    ...mapWritableState(useLayoutStore, ['isSidebarCollapsed']),

    filteredGroups() {
      /**
       * Recursively
       */
      const filterItems = (items) => {
        const result = items.filter((item) => {
          const result = !item.access || this.$auth.user.can(item.access);

          if (result && item.items) {
            item.items = filterItems(item.items);
          }

          return result;
        });

        return result.filter(({ items }) => !items || items.length);
      };

      return filterItems(this.groups);
    },
  },

  watch: {
    '$route.path': {
      handler(path) {
        if (path === '/')
          this.$router.push(this.filteredGroups[0].items[0].path);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.backdrop,
aside {
  z-index: $zindex-fixed;
}

.backdrop {
  background: $modal-backdrop-bg;
  opacity: $modal-backdrop-opacity;
}

aside {
  width: rem(280);
  background: linear-gradient(
    160deg,
    $table-hover-bg 0%,
    darken($table-hover-bg, 2%) 70%,
    darken($table-hover-bg, 1%) 100%
  );

  .is-collapsed & {
    width: rem(70) + $scrollbar-width;
  }
}

.toggle {
  font-size: rem(12);
  color: $gray-600;
  padding: rem(6);
}

.inner-item {
  padding: map-get($spacers, 4);

  @include media-breakpoint-up(xl) {
    padding: map-get($spacers, 5);
  }

  .is-collapsed & {
    padding-left: rem(15);
    padding-right: rem(15);
  }
}

.logo {
  font-weight: 600;
  font-size: rem(16);

  img {
    height: rem(40) + $scrollbar-width;
  }
}

.menu-item {
  a {
    display: flex;
    color: $primary;
    padding: map-get($spacers, 2);
    margin-bottom: map-get($spacers, 1);
    outline: none;

    &:hover,
    &:focus {
      color: inherit;
    }
  }

  .router-link-active {
    background-color: $primary;

    &,
    &:hover,
    &:focus {
      color: $white;
    }
  }

  i {
    padding: rem(10);
  }

  .is-collapsed & {
    a {
      display: inline-flex;
      padding: 0;
      margin-bottom: map-get($spacers, 3);
    }
  }
}

.children {
  padding: map-get($spacers, 2) 0;

  .menu-item {
    a {
      padding: rem(10) rem(18) rem(10) rem(61);
    }

    .router-link-active {
      background-color: rgba($primary, 0.25);
      color: inherit;
    }
  }

  .router-link-active + & {
    display: block;
  }

  &,
  .is-collapsed & {
    display: none;
  }
}

.menu-label,
.user-text {
  .is-collapsed & {
    display: none;
  }
}

.user-text {
  padding: 0 rem(20);
}
</style>
