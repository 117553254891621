import debounce from 'lodash/debounce';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const calculateIsSidebarCollapsed = () => innerWidth < 1200;

export const layoutStorePlugin = ({ store }) => {
  if (store.$id !== 'layout') return;

  const handleResize = debounce(() => {
    store.setIsSidebarCollapsed(calculateIsSidebarCollapsed());
  }, 100);

  addEventListener('resize', handleResize);
};

export const useLayoutStore = defineStore('layout', () => {
  const isSidebarCollapsed = ref(calculateIsSidebarCollapsed());

  function setIsSidebarCollapsed(value) {
    isSidebarCollapsed.value = value;
  }

  return { isSidebarCollapsed, setIsSidebarCollapsed };
});
