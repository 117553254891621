<template>
  <div class="d-flex min-vh-100 justify-content-center align-items-center">
    <div class="container text-center">
      <div class="display-1 text-danger mb-3"><ExclamationOctagonIcon /></div>
      <h1 class="fs-2 fw-bold text-danger">
        {{ infoStore.error.title }}
      </h1>
      <p>{{ infoStore.error.description }}</p>
    </div>
  </div>
</template>

<script setup>
import { useInfoStore } from '../stores/info';

import ExclamationOctagonIcon from 'bootstrap-icons/icons/exclamation-octagon.svg';

const infoStore = useInfoStore();
</script>
