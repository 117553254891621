import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', () => {
  const data = ref(null);
  const isLoading = ref(false);
  const user = ref(null);

  function setData(value) {
    data.value = value;
  }

  function setIsLoading(value) {
    isLoading.value = value;
  }

  function setUser(value) {
    user.value = value;
  }

  return { data, isLoading, user, setData, setIsLoading, setUser };
});
