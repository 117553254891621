import BaseModel from './BaseModel';
import users from '../../api/users';

export default class User extends BaseModel {
  static api = users;

  get avatarURL() {
    return `https://ui-avatars.com/api/?name=${this.name}&background=df5751&color=ffffff`;
  }

  can(permission) {
    return Boolean(this.get(`Role.permissions.${permission}`));
  }
}
